
import axios from "axios";
import Links from "../constants/Links";


/**
 * 
 * Question upload API endpoint
*/

const Question = {
  /**
   * Creation of question
   * @param {*} data 
   * @returns 
   */
  create: async (data) => {
    return axios.post(Links.question.create, data)
  }, 

  /**
   * Fetching options for question
   * @param {*} questionId Selected question
   * @returns 
   */
  fetchOption: async (questionId) => {
    return axios.get(Links.question.fetchOption + "?question=" + questionId)
  },

  /**
   * Updating question
   * @param {*} questionId Selected question
   * @param {*} question 
   * @returns 
   */
  updateQuestion: async (questionId, question) => {
    return axios.put(Links.question.update + "/" + questionId, question)
  },

  /**
   * Deleting of question based on the selected id
   * @param {*} questionId Selected question
   * @returns 
   */
  deleteQuestion: async (questionId) => {
    return axios.delete(Links.question.delete + "/" + questionId)
  },

  /**
   * Make a question compulsory
   * @param {*} questionId Selected question Id
   * @returns 
   */
  compulsoryQuestion: async (questionId) => {
    return axios.post(Links.question.compulsory, questionId)
  },

  /**
   * Fetch all question based on the selected question
   * @param {*} courseId selected courseId
   * @param {*} page Selected page
   * @param {*} size Selected page size
   * @returns 
   */
  allQuestion: async (courseId, page, size) => {
    return axios.get(Links.question.all + "?course=" + courseId + "&page=" + page + "&size=" + size)
  },

  uploadQuestion: async (data) => {
    return axios.post(Links.question.upload, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }
}; 


export default Question;