<template>
  <div class="text-center">
    <v-snackbar
        v-model="visible"
        :multi-line="false"
        timeout="15000"
        :color="type"
        bottom
        right

        transition="slide-x-transition"
    >
      {{ '' }}

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="close"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import AppTypes from "@/shared/constants/apptypes";

export default {
  name: "MessageBox",
  props: {
    show: Boolean,
    message: String,
    type: String, // success, warning or error
  },

  data: () => ({
    visible: false
  }),

  watch: {
    show: function (newVal) {
      const lafia = AppTypes.CURRENT === AppTypes.LAFIA
      if (newVal) {
        this.$toast(this.message, {
          type: this.type,
          position: lafia ? "top-center" : "top-right",
          timeout: lafia ? 5000 : 7000,
          closeOnClick: !lafia,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: true,
          hideProgressBar: !lafia,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }
    },
  },
  methods: {
    close() {
      // this.$props.show = false;
      this.$emit('close-message');
    },

    showNotification({type = 'success', message, timeout = 3000}) {
      this.color = {
        success: 'success',
        error: 'error',
        warning: 'warning',
        info: 'info'
      }[type] || 'default';

      // this.timeout = timeout;
      // this.message = message;
      // this.snackbar = true;

      const lafia = AppTypes.CURRENT === AppTypes.LAFIA
      this.$toast(message, {
        type: type,
        position: lafia ? "top-center" : "top-right",
        timeout: timeout,
        closeOnClick: !lafia,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: true,
        hideProgressBar: !lafia,
        closeButton: "button",
        icon: true,
        rtl: false
      });


    }
  },

  computed: {
    icon: function () {
      return (this.type === 'success') ? 'check-circle' : (this.type === 'error' ? 'alert-circle' : (this.type === 'info' ? "information" : 'alert'))
    }
  }
}
</script>

<style scoped>
* {
  color: white;
}
</style>
