// src/plugins/notify.js
import Vue from 'vue';
import MessageBox from "@ui/components/MessageBox";

const NotificationPlugin = {
    install(Vue) {
        // Create a new Vue instance for notifications
        const notificationInstance = new Vue({
            render: h => h(MessageBox)
        }).$mount();

        // Add the mounted element to the document
        document.body.appendChild(notificationInstance.$el);

        // Add the notification method to Vue prototype
        Vue.prototype.$notify = function(options) {
            notificationInstance.$children[0].showNotification(options);
        };
    }
};

export default NotificationPlugin;
