import axios from "axios";
import ApplicationStatistics from "@/shared/apis/applications"
import Annoucement from "@/shared/apis/annoucement";
import LoginUtil from "../login/LoginUtil";
import Applicants from "@/shared/apis/applicants";
import Hostel from "@/shared/apis/hostel";
import Schools from "@/shared/apis/schools";
import Auth from "@/shared/apis/auth";
import ProgrammeChange from "@/shared/apis/programmeChange";
import Requirements from "@/shared/apis/requirements";
import Downloads from "@/shared/apis/downloads";
import Results from "@/shared/apis/results";
import Course from "@/shared/apis/course";
import Students from "@/shared/apis/students";
import Session from "@/shared/apis/session";
import ProgrammeType from "@/shared/apis/programmeType";
import Programmes from "@/shared/apis/programmes";
import Department from "@/shared/apis/department";
import Lecturer from "@/shared/apis/lecturer";
import Nationality from "@/shared/apis/nationality";
import Admission from "@/shared/apis/admission";
import ProgrammeSettings from "@/shared/apis/programmeSettings";
import Transcript from "@/shared/apis/transcript";
import Semester from "@/shared/apis/semester";
import BasicInformation from "@/shared/apis/basicInformation";
import Global from "@/shared/apis/global";
import Signature from "@/shared/apis/signature";
import ProfilePicture from "@/shared/apis/profilePicture";
import Biometric from "@/shared/apis/biometric";
import StudentInformation from "@/shared/apis/studentInformation";
import SchoolFees from "@/shared/apis/schoolFees";
import User from "@/shared/apis/user";
import System from "@/shared/apis/system";
import Faculty from "@/shared/apis/faculty";
import PaymentPlatforms from "@/shared/apis/paymentPlatforms";
import SplitAccounts from "@/shared/apis/SplitAccounts";
import AuditTrail from "@/shared/apis/audittrail";
import Hospital from "@/shared/apis/hospital";
import Honour from "@/shared/apis/honour";
import DefaultDocument from "@/shared/apis/defaultDocument";
import Awards from "@/shared/apis/awards"
import Illness from "@/shared/apis/illness";
import ProgrammeLevel from "@/shared/apis/programmelevel";
import DataCorrection from "@/shared/apis/dataCorrection";
import ScratchCard from "@/shared/apis/scratchCard";
import ScreeningReason from "@/shared/apis/screeningReason";
import UtmeCombination from "@/shared/apis/utmeCombination";
import External from "@/shared/apis/external";
import StudentProgressStatus from "@/shared/apis/studentProgressStatus";
import Payments from "@/shared/apis/payments";
import PortalDocument from "@/shared/apis/portalDocument";
import StudentProgress from "@/shared/apis/studentProgress";
import Faker from "@/shared/apis/faker";
import Support from "@/shared/apis/support";
import SSCECombination from "@/shared/apis/ssceCombination";
import DepartmentUtme from "@/shared/apis/departmentUtme";
import Question from "@/shared/apis/question";



// for refresh token
Window.refreshingToken = false;

// timeout
axios.defaults.timeout = 1000 * 60 * 10; // 10 minutes


axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    let token = LoginUtil.getAccessToken()
    if(token &&
        (config.url !== undefined && !config.url.includes('authenticate') && !config.url.includes('bio-auth') && !config.url.includes('refresh-token'))){
        config.headers.common['Authorization'] = `Bearer ${token}`
        config.withCredentials=true
    }

    config.timeout = 10 * 60 * 1000; // 10 mins

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


// response interceptors

axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx
        // if the response data is empty, set it to null
        if(response.data === ""){
            response.data = null
        }


        return response;
    }, async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== "/authenticate" && err.response && LoginUtil.getRefreshToken() !== "") {
            // Access Token was expired
            if (err.response.status == 401) {
                if(err.config.url !== "/refresh-token"){
                    if(!originalConfig._retry){
                        originalConfig._retry = true;
                        Window.refreshingToken = true
                        try {
                            // refresh the token
                            const rs = await axios.put("/refresh-token", {
                                token: LoginUtil.getRefreshToken(),
                            });

                            // free other requests from giving errors
                            Window.refreshingToken = false

                            LoginUtil.setAccessToken(rs.data.accessToken)
                            LoginUtil.setRefreshToken(rs.data.refreshToken)

                            return axios(originalConfig);
                        } catch (_error) {
                            return Promise.reject(_error);
                        }
                    } else{
                        window.location.href = '/login'
                    }
                } else{
                    // clear stored tokens
                    LoginUtil.resetRefreshToken()
                    LoginUtil.resetAccessToken()
                    // reload
                    // window.location.reload()
                }

            }
        }
        return Promise.reject(err);
});


/**
 * Collection of API hooks for the system
 */
const Api = {
    applications: ApplicationStatistics,

    admission: Admission,

    annoucement: Annoucement,

    applicants: Applicants,

    auditTrails: AuditTrail,

    auth: Auth,

    awards: Awards,

    basicInformation: BasicInformation,

    biometric: Biometric,

    course: Course,

    dataCorrection: DataCorrection,

    defaultDocument: DefaultDocument,

    department: Department,

    downloads: Downloads,

    external: External,

    faculty: Faculty,

    global: Global,

    honours: Honour,

    hospital: Hospital,

    hostel: Hostel,

    illness: Illness,

    lecturer: Lecturer,

    nationality: Nationality,

    payment: Payments,

    paymentPlatforms: PaymentPlatforms,

    studentProgressStatus: StudentProgressStatus,

    portalDocument: PortalDocument,

    programmes: Programmes,

    programmeChange: ProgrammeChange,

    programmeLevel: ProgrammeLevel,

    programmeType: ProgrammeType,

    profilePicture: ProfilePicture,

    programmeSettings: ProgrammeSettings,

    requirements: Requirements,

    results: Results,

    scratchCard: ScratchCard,

    screeningReason: ScreeningReason,

    schools: Schools,

    schoolFees: SchoolFees,

    semester: Semester,

    session: Session,

    signature: Signature,

    splitAccounts: SplitAccounts,

    students: Students,

    studentInformation: StudentInformation,

    studentProgress: StudentProgress,

    system: System,

    support: Support,

    transcript: Transcript,

    utmeCombination: UtmeCombination,

    ssceCombination: SSCECombination,

    user: User,

    faker: Faker,

    departmentUtme: DepartmentUtme,

    question: Question,
}


export default Api;
