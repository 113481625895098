<template>
  <v-row justify="center">
    <loading :active.sync="showLoading"
             :can-cancel="false"
             loader="dots"
             :color="$vuetify.theme.currentTheme.primary"
             background-color="#fff"
             :is-full-page="true"/>
  </v-row>
</template>


<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: "LoadingDialog",
  components: {
    Loading
  },
  props: {
    show: Boolean,
    message: String,
  },
  data: () => ({
    showLoading: false,
    loadingMessage: ''
  }),
  methods: {
    start(){
      this.showLoading = true
    },

    finish(){
      this.showLoading = false
    }
  },

  watch: {
    show: function(val){
      this.showLoading = val
    }
  }
}
</script>

<style scoped>
.transparent {
  background-color: transparent;
}

</style>
