import axios from "axios";
import Links from "../constants/Links";

/**
 * This is the Semester API hook, for handling semester in the system (C) 2021-2023
 */

const Semester = {
    /**
     * update semester
     * @param {string} semester
     * @param {object} data
     * @returns {Promise<object>}
     */
    update: async (semester, data) => {
        return axios.put(Links.semester.update + semester, data)
    },

    /**
     * activate a semester i.e open a semester
     * @param {string} semester
     *@returns {Promise<boolean>}
     */
    activate: async (semester) => {
        return axios.put(Links.semester.activate + semester)
    },

    makeOptional: async (semester) => {
        return axios.put(Links.semester.makeOptional + "/" + semester)
    },

    cancelOptional: async (semester) => {
        return axios.put(Links.semester.cancelOptional + "/" + semester)
    },

    getOptional: async (semester) => {
        return axios.get(Links.semester.getOptional + "/" + semester)
    },

    updateOptional: async (data) => {
        return axios.post(Links.semester.updateOptional, data)
    },

    /**
     * close a semester i.e close a semester
     * @param {string} semester
     * @returns {Promise<boolean>}
     */
    deactivate: async (semester) => {
        return axios.put(Links.semester.deactivate + semester)
    },

    /**
     * fetch paid sessions a student paid for
     * @param {string} studentId
     * @returns {Promise<any[]>}
     */
    getPaidSemesters: async (studentId, session) => {
        return axios.get(Links.semester.getPaidSemesters + '?student=' + studentId + "&session=" + session)
    },

    /**
     * fetch a session for payment
     * @param {string} studentId userid
     * @returns {Promise<any[]>}
     */
    getPaidAndPending: async (studentId, session) => {
        return axios.get(Links.semester.getPaidAndPending + '?student=' + studentId + "&session=" + session);
    },
}

export default Semester
